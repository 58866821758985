@import url('https://fonts.googleapis.com/css2?family=Space+Mono:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@400;500;600&display=swap');

* {
  scrollbar-width: none;
  scrollbar-color: #101;
  scroll-behavior: smooth;
}
body
{
  background-color: #141727;
  font-family: 'Space Mono', monospace;
  color: #F6F6F6;
}

html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video
{
  font-family: 'Space Mono', monospace;
  margin: 0;
  padding: 0;
  border: 0;
}
a {
  text-decoration: none;
}

li {
  list-style-type: none;
}
ul {
  margin-left: 0;
  padding-left: 0;
}